

// NOTE:
// ------
// PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
// WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
// body {
//     margin: 0;
//     font-family: "Noto Sans!important";

// }
.form-group label, .form-group p {
    font-size: 14px!important;
    text-transform: uppercase;
}
.dynamic-details label {
    font-size: 16px!important;
}